import type {
	ComcCommands, ComcCommand,
} from '@nfps.dev/runtime';


import {
	XC_CMD_ACCOUNT_CHANGED,
	XC_CMD_CONNECT,
	XC_CMD_DISCONNECT,
	XC_CMD_EXEC_CONTRACT,
	XC_CMD_SECRET_DECRYPT,
	XC_CMD_SECRET_ENCRYPT,
	XC_CMD_SIGN_AUTO,
	XC_CMD_STORE_DATA,
	XC_CMD_FETCH_DATA,
	ls_read_json,
	ls_write_json
} from '@nfps.dev/runtime';

import type {
	AsJson,
	Dict,
	HexMixed,
	JsonObject,
	JsonValue,
	Promisable,
} from '@blake.regalia/belt';

import type {
	Window as KeplrWindow, OfflineAminoSigner, SecretUtils, StdSignDoc, StdSignature,
	Key as KeplrKey,
} from '@keplr-wallet/types';


import {
	oda,
	uuid_v4,
	base64_to_buffer,
	buffer_to_base64,
	buffer_to_text,
} from '@blake.regalia/belt';
import type { A, O } from 'ts-toolbelt';
import type {DecodedProtobuf, TypedAminoMsg} from '@solar-republic/neutrino';
import { decode_protobuf, bech32_encode } from '@solar-republic/neutrino';


// import App from './App.svelte';

// const k_app = new App({
// 	target: document.getElementById('app')!,
// });

// export default k_app;

const post = (w_msg: JsonValue, a_transfers?: Transferable[]) => window.top?.postMessage(w_msg, '*', a_transfers);

let y_keplr = window.keplr!;

let fk_resolve_load = (w: unknown) => {};
let dp_load = new Promise((fk) => fk_resolve_load = fk);
let b_loaded = false;
window.onload = () => {
	b_loaded = true;
	y_keplr = window.keplr!;
	fk_resolve_load(y_keplr);
};

declare global {
	interface Window extends KeplrWindow {
		// keplr: {
		// 	enable(si_chain: string): Promise<void>;
		// 	getOfflineSigner(): Promise<
		// };
	}
}

type InitTuple = ComcCommands[typeof XC_CMD_CONNECT]['req'];

type ComcHostHandlers = {
	[xc_cmd in ComcCommand]: (this: KeplrSession, w_arg: ComcCommands[xc_cmd]['req']) => Promisable<ComcCommands[xc_cmd]['res']>;
};

class KeplrSession {
	static async init(a_init: InitTuple) {
		const y_session = new KeplrSession(a_init);

		return await y_session._init();
	}

	_si_chain: string;
	_y_signer_amino!: OfflineAminoSigner;
	_g_account!: KeplrKey;
	_sa_signer!: string;
	_y_enigma!: SecretUtils;

	constructor(protected _a_init: InitTuple) {
		const [
			p_href,
			si_chain,
		 ] = _a_init;

		this._si_chain = si_chain;

		console.log('Received comc request from embedder claiming to be '+p_href);
	}

	get account(): KeplrKey {
		return this._g_account;
	}

	async _init() {
		const {_si_chain} = this;

		this._y_enigma = y_keplr.getEnigmaUtils(this._si_chain);

		ADDING: {
			// attempt to open a connection
			try {
				await y_keplr.enable(_si_chain);
			}
			catch(z_enable) {
				console.error(z_enable);

				if(z_enable instanceof Error) {
					// pulsar-3 not yet added; try adding
					if(z_enable.message.includes('chain info') && 'pulsar-3' === _si_chain) {
						await y_keplr.experimentalSuggestChain({
							rpc: 'https://rpc.pulsar3.scrttestnet.com',
							rest: 'https://api.pulsar3.scrttestnet.com',
							chainId: 'pulsar-3',
							chainName: 'Secret Testnet Pulsar 3',
							stakeCurrency: {
								coinDenom: 'SCRT',
								coinMinimalDenom: 'uscrt',
								coinDecimals: 6,
								coinGeckoId: 'secret',
							},
							// walletUrl: 'https://wallet.keplr.app/chains/secret-network',
							// walletUrlForStaking: 'https://wallet.keplr.app/chains/secret-network',
							bip44: {
								coinType: 529,
							},
							alternativeBIP44s: [
								{
									coinType: 118,
								},
							],
							bech32Config: {
								bech32PrefixAccAddr: 'secret',
								bech32PrefixAccPub: 'secretpub',
								bech32PrefixValAddr: 'secretvaloper',
								bech32PrefixValPub: 'secretvaloperpub',
								bech32PrefixConsAddr: 'secretvalcons',
								bech32PrefixConsPub: 'secretvalconspub',
							},
							currencies: [
								{
									coinDenom: 'SCRT',
									coinMinimalDenom: 'uscrt',
									coinDecimals: 6,
									coinGeckoId: 'secret',
								},
							],
							feeCurrencies: [
								{
									coinDenom: 'SCRT',
									coinMinimalDenom: 'uscrt',
									coinDecimals: 6,
									coinGeckoId: 'secret',
									gasPriceStep: {
										low: 0.1,
										average: 0.25,
										high: 0.5,
									},
								},
							],
							features: ['secretwasm'],
						});

						// retry once
						try {
							await y_keplr.enable(_si_chain);

							break ADDING;
						}
						catch(e_retry) {}
					}

					// user rejected request
					throw new Error('rejected: '+z_enable.message);
				}
			}

			try {
				this._y_signer_amino = y_keplr.getOfflineSignerOnlyAmino(_si_chain);

				// get currently connected account
				const g_account = this._g_account = await y_keplr.getKey(_si_chain);

				this._sa_signer = g_account.bech32Address;

				return this;
			}
			catch(e_read) {
				throw new Error((e_read as Error)?.message || 'An unknown error occurred: '+e_read);
			}
		}
	}
}

type ProtobufCoin = [[atu8_denom: Uint8Array], [atu8_amount: Uint8Array]];

const decode_coin = (a_coin: ProtobufCoin) => ({
	denom: buffer_to_text(a_coin[0][0]),
	amount: buffer_to_text(a_coin[1][0]),
});

const H_AMINOTIZE: Dict<(...a_args: DecodedProtobuf[]) => TypedAminoMsg> = oda(Object.create(null), {
	'/cosmos.feegrant.v1beta1.BasicAllowance': (a_coins: ProtobufCoin[]) => ({
		type: 'cosmos-sdk/BasicAllowance',
		value: {
			spend_limit: a_coins.map(decode_coin),
		},
	}),

	'/cosmos.feegrant.v1beta1.MsgGrantAllowance': ([atu8_granter]: [Uint8Array], [atu8_grantee]: [Uint8Array], [a_allowance]: [DecodedProtobuf]) => ({
		type: 'cosmos-sdk/MsgGrantAllowance',
		value: {
			granter: buffer_to_text(atu8_granter),
			grantee: buffer_to_text(atu8_grantee),
			allowance: route_proto(a_allowance),
		},
	}),

	'/secret.compute.v1beta1.MsgExecuteContract': ([atu8_sender]: [Uint8Array], [atu8_contract]: [Uint8Array], [atu8_msg]: [Uint8Array], a_funds?: ProtobufCoin[] | undefined) => ({
		type: 'wasm/MsgExecuteContract',
		value: {
			sender: bech32_encode('secret', atu8_sender),
			contract: bech32_encode('secret', atu8_contract),
			msg: buffer_to_base64(atu8_msg),
			sent_funds: a_funds?.map(decode_coin) || [],
		},
	}),
});

function route_proto(a_msg: DecodedProtobuf) {
	const [
		[atu8_type],
		[a_payload],
	] = a_msg as [
		[atu8_type: Uint8Array],
		[a_payload: DecodedProtobuf[]],
	];

	// decode message type
	const si_type = buffer_to_text(atu8_type);

	// lookup handler
	const f_handler = H_AMINOTIZE[si_type];
	if(!f_handler) {
		throw new Error(`No such Cosmos protobuf to amino mapping for message type '${si_type}'`);
	}
	
	// handle
	return f_handler(...a_payload);
}

const H_HANDLERS_RAW: ComcHostHandlers = {
	/**
	 * Request to open new connection
	 */
	async [XC_CMD_CONNECT](a_init) {
		// await for window to finish loading
		if(!b_loaded) await dp_load;

		// keplr not available
		if(!y_keplr) {
			throw new Error('unavailable');
		}
		// keplr availagle, open a session
		else {
			k_session = (await KeplrSession.init(a_init))!;

			return k_session.account;
		}
	},

	/**
	 * Disconnect and end the session
	 */
	async [XC_CMD_DISCONNECT]() {
		return y_keplr.disable(this._si_chain);
	},

	[XC_CMD_ACCOUNT_CHANGED]() {
		return new Promise((fk_resolve) => {
			const f_listener = async() => {
				window.removeEventListener('keplr_keystorechange', f_listener);

				// get currently connected account and update session
				const g_account = k_session._g_account = await y_keplr.getKey(k_session._si_chain);
				k_session._sa_signer = g_account.bech32Address;

				// resolve
				fk_resolve(g_account);
			};

			window.addEventListener('keplr_keystorechange', f_listener);	
		});
	},

	async [XC_CMD_SIGN_AUTO]([atu8_msg, sg_limit, a_auth]) {
		const {
			_si_chain, _sa_signer,
		} = this;

		// convert proto to amino
		const g_msg = route_proto(decode_protobuf(atu8_msg));

		// wrap in sign doc
		const g_doc: StdSignDoc = {
			chain_id: _si_chain,
			account_number: a_auth[0]!,
			sequence: a_auth[1]!,
			fee: {
				amount: [{
					amount: `${Math.ceil(Number(sg_limit) * 0.15)}`,
					denom: 'uscrt',
				}],
				gas: sg_limit,
			},
			msgs: [g_msg],
			memo: '',
		};

		// sign as amino doc
		const {
			signature: g_signature, signed: g_signed,
		} = await y_keplr.signAmino(_si_chain, _sa_signer, g_doc);

		return [
			g_signed,
			base64_to_buffer(g_signature.signature),
		];
	},

	[XC_CMD_SECRET_ENCRYPT]([sb16_code_hash, h_msg]) {
		return this._y_enigma.encrypt(sb16_code_hash, h_msg);
	},

	[XC_CMD_SECRET_DECRYPT]([atu8_ciphertext, atu8_nonce]) {
		return this._y_enigma.decrypt(atu8_ciphertext, atu8_nonce);
	},

	[XC_CMD_EXEC_CONTRACT]([sb16_code_hash, h_msg]) {
		throw new Error('Function not implemented.');
	},

	[XC_CMD_STORE_DATA]([w_data]) {
		const sh_key = uuid_v4();
		ls_write_json(sh_key, w_data);
		return sh_key;
	},

	[XC_CMD_FETCH_DATA]([sh_key]) {
		return ls_read_json(sh_key);
	},
}

let k_session!: KeplrSession;



const H_HANDLERS = oda(Object.create(null), H_HANDLERS_RAW);

addEventListener('message', async(d_event) => {
	try {
		const [
			si_req,
			xc_cmd,
			w_arg,
		] = d_event.data;

		const f_handler = H_HANDLERS[xc_cmd];
		if(f_handler) {
			// attempt to handle
			try {
				// success; forward to resolver
				post([si_req, 0, await f_handler.call(k_session, w_arg)]);
			}
			// catch error
			catch(e_handle) {
				// forward to rejection handler
				post([si_req, 1, (e_handle as Error).message]);
			}
		}
		else {
			console.log('Unhandled event', d_event);
		}
	}
	catch(e_receive) {}
});
